var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({attrs:{"text":"Loading Customers"}},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Equipment","sub-heading":"A list of equipment and equipment your company owns or services as part of its portfolio."}})],1),_c('column',{staticClass:"is-5"},[_c('form',[_c('text-input',{attrs:{"classes":"has-addons is-rounded","placeholder":"Search for equipment..."},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"buttons has-addons is-rounded"},[_c('submit-button',{staticClass:"is-rounded",on:{"submit":_vm.runSearch}},[_vm._v(" Search ")]),(_vm.isFiltering)?_c('action-button',{on:{"click":_vm.clearFilters}},[_c('icon',{attrs:{"icon":"times"}})],1):_vm._e(),_c('action-button',{staticClass:"is-rounded",on:{"click":function($event){_vm.filtering = !_vm.filtering}}},[_c('icon',{attrs:{"icon":"sliders"}})],1)],1)]},proxy:true}]),model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1)])],1),_c('columns',[_c('column',[_c('action-button',{staticClass:"is-small is-rounded",attrs:{"working":_vm.downloading,"left-icon":"download"},on:{"click":_vm.downloadExcel}},[_vm._v(" Excel ")])],1),_c('column',{staticClass:"is-narrow"},[_c('router-link',{attrs:{"to":{
          name: 'new-equipment'
        }}},[_c('icon',{attrs:{"icon":"plus"}}),_vm._v(" Equipment ")],1)],1)],1),_c('equipment-filters',{directives:[{name:"show",rawName:"v-show",value:(_vm.filtering),expression:"filtering"}],on:{"filter":_vm.updateAdvancedFilters}}),_c('columns',[_c('column',[_c('div',{staticClass:"index-rows"},_vm._l((_vm.equipment.data),function(equipment){return _c('div',{key:equipment.id,staticClass:"box is-marginless"},[_c('columns',[_c('column',[_c('router-link',{attrs:{"to":{
                  name: 'equipment-manager',
                  params: {
                    equipment: equipment.uuid
                  }
                }}},[_vm._v(" "+_vm._s(equipment.name)+" ")]),_c('is-overdue',{attrs:{"schedules":equipment.checklist_schedules}}),_c('br'),_vm._v(" "+_vm._s(equipment.code.value)+" ")],1),_c('div',{staticClass:"column is-narrow is-flex is-justify-content-right"},[_c('index-info',{attrs:{"label":"Customer","info":equipment.location.customer.name}})],1),_c('div',{staticClass:"column is-2 is-flex is-justify-content-right"},[_c('index-info',{attrs:{"label":"Classification","info":equipment.classification.name}})],1),_c('column',{staticClass:"is-narrow"},[_c('outcome-tag',{attrs:{"status":equipment.status}})],1)],1)],1)}),0),(_vm.equipment.data.length)?_c('pager',{attrs:{"pageable":_vm.equipment,"context":"Equipment","jump-controls":""},on:{"nav":_vm.goToPage}}):_vm._e(),_c('no-items-to-display',{attrs:{"item-count":_vm.equipment.data.length,"heading":"There is no equipment available for display."}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }