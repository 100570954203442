<template>
  <loader v-bind="{ loading }" text="Loading Customers">
    <columns>
      <column>
        <page-heading
          heading="Equipment"
          sub-heading="A list of equipment and equipment your company owns or services as part of its portfolio."
        />
      </column>
      <column class="is-5">
        <form>
          <text-input 
            v-model="filters.search"
            classes="has-addons is-rounded"
            placeholder="Search for equipment...">
            <template #right>
              <div class="buttons has-addons is-rounded">
                <submit-button 
                  @submit="runSearch"
                  class="is-rounded">
                  Search
                </submit-button>
                <action-button
                  v-if="isFiltering"
                  @click="clearFilters">
                  <icon icon="times"/> 
                </action-button>
                <action-button class="is-rounded" @click="filtering = !filtering">
                  <icon icon="sliders" />
                </action-button>
              </div>
            </template>
          </text-input>
        </form>
      </column>
    </columns>

    <columns>
      <column>
        <action-button @click="downloadExcel" :working="downloading" class="is-small is-rounded" left-icon="download">
          Excel
        </action-button>
      </column>
      <column class="is-narrow">
        <router-link :to="{
            name: 'new-equipment'
          }">
          <icon icon="plus"/>
          Equipment
        </router-link>
      </column>
    </columns>

    <equipment-filters 
      v-show="filtering"
      @filter="updateAdvancedFilters"
    />

    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="equipment in equipment.data" :key="equipment.id">
            <columns>
              <column>
                <router-link :to="{
                    name: 'equipment-manager',
                    params: {
                      equipment: equipment.uuid
                    }
                  }">
                  {{ equipment.name }}
                </router-link>
                <is-overdue :schedules="equipment.checklist_schedules"/>
                <br>
                {{ equipment.code.value }}
              </column>
              <div class="column is-narrow is-flex is-justify-content-right">
                <index-info 
                    label="Customer" 
                    :info="equipment.location.customer.name"
                  />
              </div>
              <div class="column is-2 is-flex is-justify-content-right">
                <index-info 
                    label="Classification" 
                    :info="equipment.classification.name"
                  />
              </div>
              <column class="is-narrow">
                <outcome-tag :status="equipment.status"/>
              </column>
            </columns>
          </div>
        </div>

        <pager 
          v-if="equipment.data.length" 
          :pageable="equipment" 
          context="Equipment" 
          jump-controls 
          @nav="goToPage"
        />

        <no-items-to-display
          :item-count="equipment.data.length"
          heading="There is no equipment available for display."
        />

      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
import EquipmentFilters from './partials/Filters.vue'
import IsOverdue from './partials/IsOverdue.vue'
import { common as backend } from '@/api'
import download from 'downloadjs'

const base_filters = {
  search: ''
}

export default {

  components: { 
    EquipmentFilters,
    IsOverdue,
  },

  data: () => ({
    loading: true,
    filtering: false,
    downloading: false,
    filters: { ...base_filters },
    equipment: {
      data: []
    }
  }),

  created() {
    if(this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadEquipments()
  },

  methods: {
    loadEquipments() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.equipment = data
        this.loading = false
      }, () => {
        this.loading = false
      }, { params: this.filters })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'equipment',
        query: this.filters
      });
    },
    clearFilters() {
      this.filters = { ...base_filters }
      this.$router.push({
        name: 'equipment',
      });
    },
    updateAdvancedFilters(filters) {
      this.filters = { ...this.filters, ...filters }
    },
    downloadExcel() {
      this.downloading = true
      backend.downloadFile(`/v1/equipment/download-xls`, ({ data }) => {
        download(data, `equipment-list.xlsx`, 'xlsx')
        this.downloading = false
      }, () => {
        this.downloading = false
      }, {
        customers: this.filters.customers,
        status: this.filters.status
      })
    }
  },

  computed: {
    isFiltering() {
      return Object.values(this.$route.query).length > 0
    }
  },

  watch: {
    '$route': 'loadEquipments'
  }

}
</script>