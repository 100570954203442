<template>
    <columns>
        <column>
            <data-selector multiple searchable :items="customer_list" value-key="id" label-key="name"
                v-model="filters.customers" classes="is-rounded">
                Customers
            </data-selector>
        </column>
        <column>
            <select-input v-model="filters.status" :items="statuses" classes="is-rounded">
                Status
            </select-input>
        </column>
        <column class="is-narrow is-flex is-align-items-end">
            <checkbox-input v-model="filters.overdue" inner-label="Overdue for Inspection" />
        </column>
    </columns>
</template>
<script>
import { mapGetters } from 'vuex'

const base_filters = {
    customers: [],
    status: '',
    priority: '',
    overdue: false
}

export default {

    data: () => ({
        filters: base_filters,
    }),

    async created() {
        await this.$store.dispatch('customer/loadCustomerList')
    
        let { customers, status, priority, overdue } = this.$route.query
        if (customers && customers.length) {
            this.filters.customers = this.customer_list.filter(customer => customers.includes(customer.id))
        }
        this.filters.status = status || ''
        this.filters.priority = priority || ''
        this.filters.overdue = overdue && overdue === 'true' ? true : false
    },

    methods: {
        emitFilters() {
            let filters = { ...this.filters }
            filters.customers = filters.customers.map(customer => customer.id)
            this.$emit('filter', filters)
        },
        clearFilters() {
            this.filters = base_filters
        }
    },

    computed: {
        statuses() {
            return [
                { value: 'operational', label: 'Operational' },
                { value: 'faulty', label: 'Faulty' },
            ]
        },
        ...mapGetters('customer', ['customer_list']),
    },

    watch: {
        'filters.customers': 'emitFilters',
        'filters.status': 'emitFilters',
        'filters.priority': 'emitFilters',
        'filters.overdue': 'emitFilters',
    }
}
</script>