<template>
<span v-show="totalOverdue > 0" class="has-text-danger" v-tooltip="`Overdue for ${totalOverdue} checklists`">
    <icon icon="stopwatch"/>
</span>    
</template>
<script>
import moment from 'moment'

export default {
    props: {
        schedules: {
            type: Array,
            default: () => ([])
        }
    },

    computed: {
        totalOverdue() {
            return this.schedules.filter(schedule => {
                return schedule.due_for && moment(schedule.due_for).isBefore(moment())
            }).length
        }
    }

}
</script>